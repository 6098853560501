<template>
  <div id="sideMenu" class="mt-5">
    <div class="text-left" shadow>
      <p style="font-size: 24px" class=""><b>หมวดหมู่</b></p>
      <button
        href="#"
        class="btn btn-outline-white btn-block side-menu text-left pl-0"
      >
        ถ่ายภาพ/กราฟฟิก/ออกแบบ
      </button>
      <button
        href="#"
        class="btn btn-outline-white btn-block side-menu text-left pl-0"
      >
        Link 2
      </button>
      <button
        href="#"
        class="btn btn-outline-white btn-block side-menu text-left pl-0"
      >
        Link 3
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "sideMenu",
  data() {
    return {};
  },
};
</script>
<style>
@media screen and (max-width: 992px) {
  #sideMenu {
    display: none;
  }
}
#sideMenu {
  margin-top: 5%;
  margin-left: 15%;
  padding: 0;
}

button.side-menu:hover {
  background-color: #2c3143; /* Green */
  color: white;
}
</style>
