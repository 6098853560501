import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// app.js
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import "./style/custom.css";

Vue.use(VueAxios, axios)

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
import VueCoreVideoPlayer from "vue-core-video-player";
Vue.use(VueCoreVideoPlayer, {
  lang: "en"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
