<template>
  <div id="listCourses" class="mt-5">
    <VueSlickCarousel
      v-if="isShowCourses"
      v-bind="settings"
      :arrows="true"
      :dots="false"
    >
      <span v-for="data of data" :key="data.id">
        <b-card
          border-variant="primary"
          :title="data.name"
          :img-src="img"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 mx-1 card-article"
        >
          <b-card-text
            class="short-desc"
            v-html="data.description"
          ></b-card-text>
        </b-card>
      </span>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import img from "./../../public/temp2.jpeg";
import Service from "@/service";

export default {
  name: "listCourses",
  data() {
    return {
      data: [],
      isShowCourses: false,
      img: img,
      settings: {
        focusOnSelect: false,
        arrows: true,
        // "infinite": true,
        // "initialSlide": 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        // "autoplay": true,
        ltr: true,
        // "autoplaySpeed": 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              // "centerMode": true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 375,
            settings: {
              // "centerMode": true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {},
  async created() {
    await this.getCourses();
  },
  components: {
    VueSlickCarousel,
  },
  methods: {
    async getCourses() {
      const coursesResponse = await Service.Courses.onGetCourses();
      if (coursesResponse.code == 200) {
        const result = coursesResponse.result;
        const courses = result.courses.data;
        this.data = courses;
        this.isShowCourses = true;
      }
    },
  },
};
</script>
<style lang="scss">
#listCourses {
  margin-left: 5%;
  margin-right: 5%;

  .slick-arrow.slick-next {
    position: absolute;
    z-index: 999;
    color: #1c1b1b;
    border-color: #1c1b1b;
    background-color: #777474;
    right: 0;
    top: -2%;
    opacity: 50%;
  }

  .slick-arrow.slick-prev {
    position: absolute;
    z-index: 999;
    color: #1c1b1b;
    border-color: #1c1b1b;
    background-color: #777474;
    left: calc(100% - 45px);
    top: -2%;
    opacity: 50%;
  }
  .short-desc div.course-description-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .card-article {
    font-size: 12px;
    max-height: 750px;
    overflow: hidden;
  }
  .card-article .card-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .card-title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 2px;
    margin-top: 0;
  }
  img.card-img-top {
    max-width: 100%;
    height: auto;
  }
  .card-body {
    padding: 3% 3% 3% 5%;
  }
}
</style>
