<template>
  <div id="sideMenuMobie">
    <!--    <b-button variant="dark" v-b-toggle.sidebar-1 style="border-radius:0"><b-icon-list></b-icon-list>หมวดหมู่</b-button>-->
    <b-sidebar id="sidebar-1" class="" title="หมวดหมู่" shadow>
      <div class="px-3 py-2">
        <a href="#">หน้าหลัก</a>
        <hr />
        <a>ถ่ายภาพ/กราฟฟิก/ออกแบบ</a>
        <hr />
        <a>หน้า3</a>
        <hr />
        <a>หน้า4</a>
        <hr />
      </div>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  name: "sideMenuMobie",
  data() {
    return {};
  },
};
</script>
<style></style>
