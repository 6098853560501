<template>
  <div id="topNav">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-nav>
        <b-nav-item
          v-b-toggle.sidebar-1
          class="my-2 my-sm-0 pt-0 m-0"
          id="menu"
          size="sm"
          ><b-icon-list-ul style="margin-top: 5px"></b-icon-list-ul
          >หมวดหมู่</b-nav-item
        >
      </b-navbar-nav>
      <b-navbar-brand class="navbar-brand mx-auto pl-4" href="#"
        >NavBar</b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"
        ><b-icon-list variant="light"></b-icon-list
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" style="color: white" is-nav>
        <b-navbar-nav>
          <b-nav-form>
            <b-input-group size="sm" id="search">
              <b-form-input
                size="sm"
                placeholder="ค้นหาคอร์ส..."
              ></b-form-input>
              <b-input-group-prepend is-text @click="search()"
                ><b><b-icon-search></b-icon-search></b
              ></b-input-group-prepend>
            </b-input-group>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import logo from "/public/img/icons/android-chrome-192x192.png";

export default {
  name: "topNav",
  component: {},
  data() {
    return {
      logo: logo,
    };
  },
  methods: {
    search() {
      alert("search");
    },
  },
};
</script>
<style>
@media screen and (min-width: 992px) {
  #menu {
    display: none;
  }
  #search {
    margin-left: 20px;
  }
}
</style>
