// import Service from "../../service/index";
// import BaseService from "../../service/service"

const Courses = {
    name: 'Courses',
    stateFactory: true,
    namespaced: true,
    state: {

    },
    mutations: {
    },
    actions: {

    }
}

export default Courses;