<template>
  <div class="home">
    <topNav />
    <sideMenuMobie />
    <adsCouse />
    <b-row>
      <b-col lg="2">
        <sideMenu />
      </b-col>
      <b-col lg="10">
        <listCourses />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import listCourses from "@/components/listCourses.vue";
import topNav from "@/components/topNav.vue";
import sideMenuMobie from "@/components/sideMenuMobie.vue";
import adsCouse from "@/components/adsCouse.vue";
import sideMenu from "@/components/sideMenu.vue";

export default {
  name: "Home",
  components: {
    listCourses,
    topNav,
    sideMenuMobie,
    adsCouse,
    sideMenu,
  },
};
</script>
<style></style>
